import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@/config/config'
import En from './en'

Vue.use(VueI18n)
const messages = {
  en: En
}
// https://github.com/kazupon/vue-i18n
const i18n = new VueI18n({
  locale: window.localStorage.getItem('locale') ? window.localStorage.getItem('locale') : config.locale,
  messages
})
export default i18n
Vue.use(VueI18n)
