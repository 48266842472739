// Application global config
let baseUrl
let endBasePointUrl
let endPurePointUrl
// TODO: brake the glass in case of emergency :)
//if (process && process.env && process.env.NODE_ENV === 'development') {
//}
if (window.location.protocol === 'http:') {
  baseUrl = 'http://hook.ubeac.io/'
  endBasePointUrl = 'http://hook.ubeac.io/'
  endPurePointUrl = 'hook.ubeac.io/'
} else {
  baseUrl = 'https://hook.ubeac.io/'
  endBasePointUrl = 'https://hook.ubeac.io/'
  endPurePointUrl = 'hook.ubeac.io/'
}

export default {
  locale: 'en',
  globalDateFormatter: 'YYYY/MM/DD HH:mm:ss.SSS',
  Urls: {
    EndPointBaseUrl: endBasePointUrl,
    BaseUrl: baseUrl,
    EndPurePointUrl: endPurePointUrl,
    SocketUrl: baseUrl + 'socket',
    CreateEndpoint: baseUrl + 'endpoint/create',
    GetOverallDataUrl: baseUrl + 'endpoint/GetOverallData',
    GetEndpointDataUrl: baseUrl + 'endpoint/GetEndpointData{/id}'
  },
  scrollTo: {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  }
}
