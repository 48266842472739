<template>
  <div
    class="page-home happy-landing landing-pages"
    @scroll="handleScroll">
    <header
      class="fixed-top"
      data-block-type="headers"
      data-id="1">
      <div class="container">
        <nav class="navbar navbar-expand-lg no-gutters px-0">
          <div class="col-8 col-sm-3 text-left">
            <a
              href="https://ubeac.io"
              class="page-logo-link">
              <img
                src="../../public/img/logo.svg"
                height="50"
                class="page-logo"
                alt="image">
            </a>
          </div>
          <button
            class="navbar-toggler d-xl-none pr-0"
            type="button"
            @click="mobileMenuCollapse = !mobileMenuCollapse">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            v-if="mobileMenuCollapse"
            class="navbar-collapse responsive-menu navbar-collapse-4 justify-content-center">
            <button @click="mobileMenuCollapse = false">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 191.414 191.414"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                enable-background="new 0 0 191.414 191.414">
                <g>
                  <path d="M107.888,96.142l80.916-80.916c3.48-3.48,3.48-8.701,0-12.181s-8.701-3.48-12.181,0L95.707,83.961L14.791,3.045   c-3.48-3.48-8.701-3.48-12.181,0s-3.48,8.701,0,12.181l80.915,80.916L2.61,177.057c-3.48,3.48-3.48,8.701,0,12.181   c1.74,1.74,5.22,1.74,6.96,1.74s5.22,0,5.22-1.74l80.916-80.916l80.916,80.916c1.74,1.74,5.22,1.74,6.96,1.74   c1.74,0,5.22,0,5.22-1.74c3.48-3.48,3.48-8.701,0-12.181L107.888,96.142z"/>
                </g>
              </svg>
            </button>
            <ul class="navbar-nav justify-content-center">
              <li>
                <img
                  src="../../public/img/logo.svg"
                  height="30"
                  class="mt-2 mb-5"
                  alt="image">
              </li>
              <li class="nav-item active">
                <a
                  class="nav-link"
                  href="https://ubeac.io" >
                  Home <span class="sr-only">(current)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('youtube')">
                  Guide
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('setupGuide')">
                  Documents
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('feature')">
                  Features
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('contactus')">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div class="collapse navbar-collapse navbar-collapse-4 d-none d-xl-block">
            <ul class="navbar-nav ml-auto justify-content-end">
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  href="#youtube">
                  Guide
                </a>
              </li>
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  href="#setupGuide">
                  Documents
                </a>
              </li>
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  href="#feature">
                  Features
                </a>
              </li>
              <li class="nav-item ml-3">
                <a
                  class="nav-link"
                  href="#contactus">
                  Contact
                </a>
              </li>
            </ul>
            <button
              class="btn px-4 ml-md-3 btn-cta"
              @click="createNewEndpoint">
              <span v-if="createEndpointLoading">
                {{ $t('landing.cta_button_loading') }}
              </span>
              <span v-else>
                {{ $t('landing.cta_button') }}
              </span>
            </button>
          </div>
        </nav>
      </div>
    </header>
    <section
      id="home"
      class="fdb-block first-section pb-2 pt-10"
      data-block-type="contents"
      data-id="2">
      <div
        fluid
        class="container">
        <b-row class="align-items-center happy-landing-main">
          <b-col
            cols="12"
            lg="7"
            order-lg="2"
            class="ml-md-auto mt-0 mt-lg-4 mt-md-0 px-0 px-lg-3 happy-landing-main--image text-center">
            <img
              alt="image"
              class="img-fluid float-lg-right"
              src="../../public/img/hook-artwork-1.png">
          </b-col>
          <b-col
            cols="12"
            lg="5"
            order-lg="1"
            class="px-0 px-lg-3 happy-landing-main--text">
            <h3 v-if="false">
              <span
                class="text-primary text-slogan"
                v-html="$t('landing.slogan')">
              </span>
            </h3>
            <h1
              class="text-h2 text-primary px-3 px-lg-0"
              v-html="$t('landing.banner_title')">
            </h1>
            <p class="text-h3 px-3 px-lg-0">
              {{ $t('landing.banner_description') }}
            </p>
            <p class="happy-landing-main--btn">
              <button
                class="btn btn-lg btn-cta btn-cta-second mt-3"
                @click="createNewEndpoint">
                <span v-if="createEndpointLoading">
                  Creating new Endpoint ...
                </span>
                <span v-else>
                  Create Endpoint
                </span>
              </button>
            </p>
          </b-col>
          <b-col
            cols="10"
            order="3"
            class="mt-1 offset-1">
            <b-row
              class="text-center justify-content-center">
              <b-col
                cols="12"
                sm="4"
                xl="3"
                class="m-auto pt-2 pt-lg-4">
                <h2>
                  <strong>
                    {{ overallData.endpointCount }}
                    <!--<AnimateNumber :number="overallData.endpointCount"></AnimateNumber>-->
                  </strong>
                </h2>
                <p>Endpoint Count</p>
              </b-col>
              <b-col
                cols="12"
                sm="4"
                xl="3"
                class="m-auto pt-2 pt-lg-4">
                <h2>
                  <strong>
                    {{ parseInt(overallData.requestCount).toLocaleString() }}
                    <!--<AnimateNumber :number="overallData.requestCount"></AnimateNumber>-->
                  </strong>
                </h2>
                <p>Request Count</p>
              </b-col>
              <b-col
                cols="12"
                sm="4"
                xl="3"
                class="m-auto pt-2 pt-lg-4">
                <h2 class="socket-status-box">
                  <span
                    :class="{'badge-success': socket && socket.status === 'connected' }"
                    class="badge badge-secondary pr-sm-0">
                    &#9679;
                  </span>
                  <small class="text-capital">
                    {{ socket ? socket.status : 'Unknown' }}
                  </small>
                </h2>
                <p>Socket Status</p>
              </b-col>
              <b-col
                cols="12"
                class="m-auto">
                <HeartRate
                  beat-by-global-data="true"></HeartRate>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </section>
    <!-- section 3 -->
    <section
      class="fdb-block section-logos"
      data-block-type="testimonials"
      data-id="3">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <div class="justify-content-center">
              <a href="https://www.arduino.cc/" target="_blank" >
                <img
                  alt="Arduino"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/arduino.png">
              </a>
              <a href="https://www.raspberrypi.org/" target="_blank" >
                <img
                  alt="Raspberrypi"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/raspberrypi.png">
              </a>
              <a href="https://www.mist.com/" target="_blank" >
                <img
                  alt="Mist"
                  height="80"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/mist.png">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.bridou_n.beaconscanner" target="_blank" >
                <img
                  alt="Beacon Scanner"
                  height="80"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/beacon.png">
              </a>
              <a href="https://play.google.com/store/apps/details?id=tech.unismart.dc" target="_blank" >
                <img
                  alt="Data Collector"
                  height="80"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/data-collector.png">
              </a>
              <br>
              <a href="https://ruuvi.com" target="_blank" >
                <img
                  alt="Orange pi"
                  width="150"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/ruuvi.png">
              </a>
              <a href="http://www.orangepi.org/" target="_blank" >
                <img
                  alt="Orange pi"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/orangepi.png">
              </a>
              <a href="https://www.aprbrother.com/en/index.htm" target="_blank" >
                <img
                  alt="April Brothers"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/ab.png">
              </a>
              <a href="http://www.banana-pi.org/" target="_blank" >
                <img
                  alt="BPI"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/bpi.png">
              </a>
              <a href="https://www.ingics.com/" target="_blank" >
                <img
                  alt="INGICS"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/ingics.png">
              </a>
              <a href="https://www.bluecats.com/" target="_blank" >
                <img
                  alt="Blue Cats"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/blue-cats.png">
              </a>
              <a href="https://www.jaalee.com/" target="_blank" >
                <img
                  alt="JAALEE"
                  height="100"
                  class="ml-3 mr-3 mb-2 mt-2"
                  src="../../public/logos/jaalee.png">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Youtube link -->
    <section
      id="youtube"
      class="fdb-block section-link"
      data-block-type="testimonials"
      data-id="13">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-10 col-lg-7">
            <h2 class="text-section-title">Guide</h2>
          </div>
        </div>
        <div class="row mt-5 mb-3 justify-content-center">
          <div class="col-12 section-featured-video">
            <b-embed
              :src= "featureVideo.url"
              :alt="featureVideo.name"
              type="iframe"
              aspect="16by9"
              allowfullscreen
              style="border-radius: 6px;"
            />
          </div>
          <b-container
            fluid
            bg-dark
            class="p-0 mt-5 section-thumbnail">
            <b-row class="row-fixture">
              <b-col>
                <carousel
                  :nav="true"
                  :nav-text="['','']"
                  :dots="false"
                  :margin="10"
                  :loop="true"
                  :responsive="{
                  0:{
                  items:2
                  },
                  600:{
                  items:3
                  },
                  1000:{
                  items:6
                  }
                  }"
                  :nav-speed="true" >
                  <b-col
                    v-for="video in videourl"
                    class="mb-3 text-left p-0">
                    <div
                      v-b-tooltip.bottom
                      :title="video.name"
                      target="_blank"
                      class="section-thumbnail-thumbnail"
                      @click="videoPlayable(video.id)">
                      <b-img
                        :src= "`https://i.ytimg.com/vi/${video.id}/default.jpg`"
                        :alt="video.name"
                        thumbnail
                        fluid
                        class="thumbnail-img p-0"/>
                      <div class="overlay"/>
                      <!-- <span class="thumbnail-text mt-2"> {{ video.name }} </span> -->
                    </div>
                  </b-col>
                </carousel>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </section>
    <section
      id="setupGuide"
      class="fdb-block"
      data-block-type="testimonials"
      data-id="4">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-10 col-lg-7">
            <h2 class="text-section-title">Easy Setup</h2>
          </div>
        </div>

        <div class="row mt-5 mb-3 justify-content-center">
          <div class="col-md-10 col-lg-3 ml-auto mr-auto text-center">
            <p>
              <img
                alt="step 1"
                height="120"
                src="../../public/img/easy-step-1.png"/>
            </p>
            <h3 class="text-left">
              <strong>
                Get an Endpoint (URL)
              </strong>
            </h3>
            <p class="text-h5 mt-4 text-left">
              When you click on the “Create Hook” button, a unique URL is generated for the use of a
              broadcasting application. After setting your device, anyone with this link can access a stream of
              incoming data from anywhere. It’s a handy solution for teams to collaborate remotely.
            </p>
          </div>
          <div class="col-md-10 col-lg-3 pt-5 pt-lg-0 ml-auto mr-auto text-center">
            <p>
              <img
                alt="step 2"
                height="120"
                src="../../public/img/easy-step-2.png"/>
            </p>
            <h3 class="text-left">
              <strong>
                Set Your Device
              </strong>
            </h3>
            <p class="text-h5 mt-4 text-left">
              uBeac’s Hook is compatible with a wide range of IoT devices and applications. It is a simple
              matter to configure your device to forward data to your given URL. You may then make POST,
              GET, DELETE, and OPTIONS requests with any header and content.
            </p>
          </div>

          <div class="col-md-10 col-lg-3 pt-5 pt-lg-0 ml-auto mr-auto text-center">
            <p>
              <img
                alt="step 3"
                height="120"
                src="../../public/img/easy-step-3.png"/>
            </p>
            <h3 class="text-left">
              <strong>
                View requests
              </strong>
            </h3>
            <p class="text-h5 mt-4 text-left">
              Requests sent to your URL are logged in real-time. The number and frequency of requests are
              easily visualized in the Request Per Second and Heart Beat widgets. Deftly change the view
              format for the body of your requests. Export your data in a single JSON file.
            </p>
          </div>
        </div>
        <div 
          id="document"
          class="row">
          <div class="request-details-no-result m-auto col-12 col-lg-12 col-xl-12">
            <SampleRequest class="mt-5" url="https://hook.ubeac.io/12345678"></SampleRequest>
          </div>
        </div>
      </div>
    </section>

    <section
      id="feature"
      class="fdb-block feature-section"
      data-block-type="call_to_action"
      data-id="6">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-12 col-lg-12 text-center">
            <div class="fdb-box">
              <h2 class="text-h2 text-section-title">
                {{ $t('landing.second_cta_text') }}
              </h2>
              <div class="row text-right align-items-center-lg align-items-end pt-5">
                <div class="col-7 col-sm-4 m-auto mb-md-0 mt-md-0 m-lg-auto">
                  <img
                    alt="image"
                    class="img-fluid br-b-0"
                    src="../../public/img/hook-artwork-2.png">
                </div>

                <div class="col-12 col-md-7 col-lg-6 col-xl-5 m-auto text-left pt-5 pt-md-0">
                  <p>
                    With your Hook endpoint URL, you can test the connectivity of your devices and view sensor
                    data from anywhere, for free - no sign up - no margin. Your unique Hook URL is one click away.
                  </p>
                  <p class="pt-2">
                    Hook allows you to inspect Request Headers Inspect Header requests in detail.
                  </p>
                  <p class="pt-2">
                    Hook supports output in JSON, YAML and XML, as well as in HTML for in-browser testing.
                  </p>
                </div>
              </div>
              <p class="mt-4">
                <button
                  class="btn btn-lg ml-md-3 btn-cta btn-cta-second"
                  @click="createNewEndpoint">
                  <span v-if="createEndpointLoading">
                    {{ $t('landing.cta_button_loading') }}
                  </span>
                  <span v-else>
                    {{ $t('landing.cta_button') }}
                  </span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <footer
      class="fdb-block footer-small"
      data-block-type="footers"
      data-id="9">
      <div class="container">
        <div class="row text-center align-items-center">
          <div class="col">
            <ul class="nav justify-content-center">
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('feature')">
                  Features
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('youtube')">
                  Guide
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('setupGuide')">
                  Documents
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="closeMenuTo('contactus')">
                  Contact
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  target="_blank"
                  href="https://www.ubeac.io">
                  uBeac
                </a>
              </li>
            </ul>
            <p class="text-h5 mt-5">{{ $t('copyright') }}
              <small>v{{ appVersion }}</small>
            </p>
          </div>
        </div>
      </div>
    </footer> -->
    <footer
      id="contactus"
      data-block-type="contacts"
      data-id="8">
      <div class="line-break d-none d-md-block" />
      <div class="container pt-5 pt-md-0">
        <div class="row align-items-top text-center text-lg-left">
          <div class="col-12 col-md-12 col-lg-8 order-2 order-lg-1 pt-5 pt-lg-0">
            <div class="row">
              <!-- About us -->
              <div class="col-12 col-md-6 col-lg-6">
                <h2 class="h2 text-center text-md-left mb-2">
                  {{ $t('footer.about_us') }}
                </h2>
                <p class="about-us pt-2 pr-0 pr-xl-5 mr-0 mr-xl-5 text-center text-md-left">
                  {{ $t('footer.about_us_desc') }}
                </p>
              </div>
              <!-- Contact us -->
              <div class="col-12 col-md-6 col-lg-6">
                <h2 class="h2 text-center text-md-left pt-4 pt-md-0 mb-2">
                  {{ $t('footer.contact_us') }}
                </h2>
                <p class="about-us pt-2 pr-0 pr-xl-4 mb-0 mr-0 mr-xl-5 text-center text-md-left">
                  {{ $t('footer.address') }}
                </p>
                <p class="about-us pr-0 pr-xl-4 mb-0 mr-0 mr-xl-5 text-center text-md-left">
                {{ $t('footer.email') }}
                <a href="#" 
                   data-name="info"
                   data-domain="ubeca"
                   data-tld="io"
                   onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
                  <img src="../../public/img/mail-white.png" />
                </a>
                </p>
              </div>
            </div>
          </div>
          <!-- Begin Mailchimp Signup Form -->
         <div
          class="col-12 col-md-12 col-lg-4 order-1 order-lg-2">
            <div class="footer-logo mx-auto text-center">
              <a
                accesskey="p"
                tabindex
                class="mx-auto"
                href="https://www.ubeac.io">
                <img
                  :alt="$t('app_title')"
                  src="../../public/img/logo-white.svg">
              </a>
            </div>
            <div
              id="mc_embed_signup"
              class="w-100">
              <form
                action="https://momentaj.us7.list-manage.com/subscribe/post?u=c8754bba497c618bd7a876992&amp;id=25c0f0f9c0" 
                method="post" 
                id="mc-embedded-subscribe-form" 
                name="mc-embedded-subscribe-form" 
                class="validate form-inline w-100"  
                target="_blank" 
                novalidate>
                <div class="row mx-auto">
                  <div class="col-12">
                    <h3 class="mb-2 text-center">
                      {{ $t('subscribe') }}
                    </h3>
                    <p class="lead mb-4">
                      {{ $t('subscribe_details') }}
                    </p>
                  </div>
                </div>
                <div
                  id="mc_embed_signup_scroll"
                  class="w-100">
                  <div class="mc-field-group input-group">
                    <div class="mt-2 w-100">
                      <div class="col-12 p-0">
                        <div class="input-group">
                          <!-- eslint-disable -->
                          <input
                            aria-label="EMAIL"
                            id="mce-EMAIL"
                            type="email"
                            name="EMAIL"
                            class="required email form-control input-subcribe mr-2"
                            :placeholder="$t('footer.email')">
                          <!-- eslint-enable -->
                          <button
                            id="mc-embedded-subscribe"
                            class="btn btn-fab btn-subscribe form-control"
                            value="Subscribe"
                            name="subscribe"
                            type="submit">
                            <img
                              alt="$t('plus')"
                              src="../../public/img/plus.svg">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div id="mce-responses" class="clear">
                    <div class="response mt-3" id="mce-error-response" style="display:none"></div>
                    <div class="response mt-3" id="mce-success-response" style="display:none"></div>
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_c8754bba497c618bd7a876992_25c0f0f9c0" tabindex="-1" value="">
                  </div>
                </div>
              </form>
            </div>
            <!--End mc_embed_signup-->
          </div>
          <!-- Footer menu -->
          <div class="w-100 order-3 order-lg-3 pt-5 pt-md-0">
            <div class="col-12 col-md-6 col-lg-8 float-left">
              <p class="pr-0 pr-xl-5 mr-0 mr-xl-5 footer-link pt-3">
                <nav class="nav flex-row justify-content-around  justify-content-md-start">
                  <a
                    class="nav-link active pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    href="#feature">
                    Features
                  </a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    href="#youtube">
                    Guide
                  </a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    href="#setupGuide">
                    Documents
                  </a>
                  <a
                    class="nav-link pl-0 pr-3 pr-sm-5 pr-md-4 pr-lg-5"
                    href="#contactus">
                    Contact
                  </a>
                  <a
                    class="nav-link pl-0 pr-0"
                    target="_blank"
                    href="https://www.ubeac.io">
                    uBeac
                  </a>
                </nav>
              </p>
            </div>
            <div class="col-12 col-md-6 col-lg-4 float-right">
              <b-row class="h1 social-icon text-center justify-content-around pt-4 mb-4">
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      accesskey="s"
                      target="_blank"
                      rel="noopener"
                      :href="$t('twitter')">
                      <img
                        alt="$t('twitter')"
                        src="../../public/img/social/twitter.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="q"
                      :href="$t('facebook')">
                      <img
                        alt="$t('facebook')"
                        src="../../public/img/social/facebook.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="x"
                      :href="$t('linkedin')">
                      <img
                        alt="$t('linkedin')"
                        src="../../public/img/social/linkedin.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="t"
                      :href="$t('youtube')">
                      <img
                        alt="$t('youtube')"
                        src="../../public/img/social/youtube.svg">
                    </a>
                  </button>
                </b-col>
                <b-col class="p-0">
                  <button
                    class="btn btn-fab"
                    type="button">
                    <a
                      target="_blank"
                      rel="noopener"
                      accesskey="oq"
                      :href="$t('github')">
                      <img
                        alt="$t('github')"
                        src="../../public/img/social/github-logo.svg">
                    </a>
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="text-center px-3 py-4 copy-right">
      <span>
        {{ $t('copyright') }}
      </span>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import AnimateNumber from '../components/AnimateNumber'
  import HeartRate from '../components/HeartRate'
  import SampleRequest from '../components/SampleRequest'
  import carousel from 'vue-owl-carousel'

  export default {
    name: 'HomePage',
    components: {AnimateNumber, HeartRate, SampleRequest, carousel},
    data () {
      return {
        // TODO: must move to store
        /* eslint-disable */
        appVersion: VERSION,
        /* eslint-enable */
        createEndpointLoading: false,
        mobileMenuCollapse: false,
        featureVideo: {
          url: 'https://www.youtube.com/embed/NpS9KnUspHk',
          id: 'NpS9KnUspHk',
          name: 'Configuring the Data Collector Application to Send HTTP Requests to uBeac'
        },
        videourl: [
          {
            url: 'https://youtu.be/JpZOgGGpgg0',
            id: 'JpZOgGGpgg0',
            name: "Configuring Ingic's IGS01 Bluetooth Gateway to Send HTTP Requests to uBea"
          },
          {
            url: 'https://youtu.be/COH1n5OJoZ8',
            id: 'COH1n5OJoZ8',
            name: "Configuring BlueCat's Edge Relay Bluetooth Gateway to Send HTTP Requests to uBeac"
          },
          {
            url: 'https://youtu.be/aLSFwgzvV9k',
            id: 'aLSFwgzvV9k',
            name: 'Configuring the Beacon Scanner Application to Send HTTP Requests to uBeac'
          },
          {
            url: 'https://youtu.be/fxAouF6eUpo',
            id: 'fxAouF6eUpo',
            name: 'Configuring the Ruuvi Application to Send HTTP Requests to uBeac'
          },
          {
            url: 'https://youtu.be/7Qu_LLbRtRw',
            id: '7Qu_LLbRtRw',
            name: "Configuring Minew's G1 Gateway to Send HTTP Requests to uBeac"
          },
          {
            url: 'https://youtu.be/Pm5w7AYxl7Y',
            id: 'Pm5w7AYxl7Y',
            name: "Configuring April Brother's BLE Gateway V.4 to Send HTTP Requests to uBeac"
          },
          {
            url: 'https://youtu.be/WQUH05EvFmU',
            id: 'WQUH05EvFmU',
            name: "Configuring Jaalee's iB006N Gateway to Send HTTP Requests to uBeac"
          },
          {
            url: 'https://youtu.be/DLCLJYjQW8g',
            id: 'DLCLJYjQW8g',
            name: "Configuring Jaalee's iB006N Gateway to Send HTTP Requests to uBeac"
          }
        ]
      }
    },
    computed: {
      ...mapState('socket', ['overallData', 'socket'])
    },
    created () {
      this.$store.dispatch('socket/initializeSocket')
      this.$store.dispatch('socket/getOverallData')
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      ...mapActions(['socket/initializeSocket']),
      closeMenuTo (url) {
        this.mobileMenuCollapse = false
        const elmnt = document.getElementById(url)
        elmnt.scrollIntoView()
      },
      createNewEndpoint () {
        this.createEndpointLoading = true
        this.$store.dispatch('socket/createEndpoint').then((result) => {
          this.$router.push({name: 'View', query: {id: result.body}})
          this.createEndpointLoading = false
        })
      },
      handleScroll (event) {
        if (window.scrollY > 0) {
          let header = document.querySelector('header')
          header.classList.add('shadow-1')
        }
        if (window.scrollY === 0) {
          let header = document.querySelector('header')
          header.classList.remove('shadow-1')
        }
      },
      videoPlayable (url) {
        this.featureVideo.url = `https://www.youtube.com/embed/${url}`
      }
    },
    mounted() {
      let amazonScript = document.createElement('script')
      amazonScript.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js')
      document.head.appendChild(amazonScript)
      //  let customScript = document.createElement('script')
      //  customScript.setAttribute('src', '../../public/lib/mailchimp.js')
      //  document.head.appendChild(customScript)
    }
  }
</script>

<style lang="sass">
  @import "../scss/components/_design-blocks"
</style>
