<template>
  <span>
    <template v-if="format">
      {{ displayNumber | numeralFormat(format) }}
    </template>
    <template v-else>
      {{ parseInt(displayNumber).toLocaleString() }}
    </template>
  </span>
</template>

<script>
  export default {
    name: 'AnimateNumbers',
    props: {
      format: {
        default: null,
        type: String 
      },
      number: {
        default: 0,
        type: Number
      }
    },
    data () {
      return {
        displayNumber: 0,
        interval: false
      }
    },
    watch: {
      number: function () {
        clearInterval(this.interval)
        if (this.number === this.displayNumber) {
          return
        }
        this.interval = window.setInterval(function () {
          if (this.displayNumber !== this.number) {
            let change = (this.number - this.displayNumber) / 10
            change = change >= 0 ? Math.ceil(change) : Math.floor(change)
            this.displayNumber = this.displayNumber + change
          }
        }.bind(this), 40)
      }
    },
    mounted () {
      this.displayNumber = this.number ? this.number : 0
    }
  }
</script>
