// import Vue from 'vue'
const signalR = require('@aspnet/signalr')

export default class {
  constructor (url) {
    this.status = 'loading'
    this.id = ''
    this.url = url

    var connection = new signalR.HubConnectionBuilder().withUrl(url).build()

    connection.on('onConnect', (clientId) => {
      this.onConnect(clientId)
      this.id = clientId
      this.status = 'connected'
    })

    connection.on('onDisconnect', (clientId, reason) => {
      this.onDisconnect(clientId, reason)
      this.status = 'disconnected'
    })

    connection.on('onJoin', (clientId) => {
      this.onJoin(clientId)
    })

    connection.on('onLeave', (clientId) => {
      this.onLeave(clientId)
    })

    connection.on('onPublicData', (data) => {
      var jdata = JSON.parse(data)
      this.onPublicData(jdata)
    })

    connection.on('onPrivateData', (clientId, data) => {
      var jdata = JSON.parse(data)
      this.onPrivateData(clientId, jdata)
    })

    connection.on('onGroupData', (groupId, data) => {
      var jdata = JSON.parse(data)
      this.onGroupData(groupId, jdata)
    })

    this.join = (groupId) => connection.invoke('join', groupId)
    this.leave = (groupId) => connection.invoke('leave', groupId)
    this.send = (data) => connection.invoke('send', data)
    this.sendToAll = (data) => connection.invoke('sendToAll', data)
    this.sendToGroup = (groupId, data) => connection.invoke('sendToGroup', groupId, data)
    this.sendToClient = (clientId, data) => connection.invoke('sendToClient', clientId, data)

    this.onPublicData = (data) => console.log('onPublicData', data)
    this.onPrivateData = (clientId, data) => console.log('onPrivateData', clientId, data)
    this.onGroupData = (groupId, data) => console.log('onGroupData', groupId, data)
    this.onConnect = (clientId) => console.log('onConnect', clientId)
    this.onDisconnect = (clientId, reason) => console.log('onDisconnect', clientId, reason)
    this.onJoin = (clientId) => console.log('onJoin', clientId)
    this.onLeave = (clientId) => console.log('onLeave', clientId)

    this.connection = connection
    this.start = () => connection.start()
  }
}
