<template>
  <div class="sample-request">
      <b-tabs
        class="card pt-2 pb-2 px-2"
        pills
        no-body
        card>
        <b-tab v-for="sampleCode in codeSample"
               :title="sampleCode.title" no-body>
          <Highlight
            :current-language="sampleCode.title"
            :code-source="sampleCode.code">
          </Highlight>
        </b-tab>
      </b-tabs>
  </div>
</template>

<script>
  import Config from '@/config/config.js'
  import Highlight from '../components/Highlight'
  export default {
    name: 'SampleRequest',
    components: {
      Highlight
    },
    props: {
      url: {
        default: 'https://hook.ubeac.io/YOUR_ENDPOINT_ID',
        require: true
      }
    },
    data () {
      const url = this.url
      const urlPath = this.url.split('.io/')[0] + '.io'
      const urlPathWithoutProtocol = urlPath.split('https://')[1]
      const urlQuery = this.url.split('.io/')[1]
      console.log(this.url)
      return {
        globalConfig: Config,
        codeSample: {
          curl: {
            title: 'cURL',
            code: `curl -X POST \\
  --url '${url}' \\
  --header 'accept: application/json' \\
  --header 'content-type: application/json' \\
  --header 'x-pretty-print: 2' \\
  --cookie 'foo=bar; bar=baz' \\
  --data '{"foo": "bar"}'

`
          },
          wget: {
            title: 'Wget',
            code: `wget --quiet \\
  --method POST \\
  --header 'cookie: foo=bar; bar=baz' \\
  --header 'accept: application/json' \\
  --header 'content-type: application/json' \\
  --header 'x-pretty-print: 2' \\
  --body-data '{"foo": "bar"}' \\
  --output-document \\
  - '${url}'

`
          },
          httpie: {
            title: 'HTTPie',
            code: `echo '{"foo": "bar"}' |  \
  http POST '${url}' \
  accept:application/json \
  content-type:application/json \
  cookie:'foo=bar; bar=baz' \
  x-pretty-print:2

`
          },
          python: {
            title: 'python',
            code: `import requests

url = "${url}"

querystring = {"foo":["bar","baz"]}

payload = "{\\"foo\\": \\"bar\\"}"
headers = {
    'cookie': "foo=bar; bar=baz",
    'accept': "application/json",
    'content-type': "application/json",
    'x-pretty-print': "2"
    }

response = requests.request("POST", url, data=payload, headers=headers, params=querystring)

print(response.text)
`
          },
          ruby: {
            title: 'Ruby',
            code: `require 'net/http'
require 'net/https'
require 'json'

uri = URI.parse("${url}")
https = Net::HTTP.new(uri.host,uri.port)
https.use_ssl = true
req = Net::HTTP::Post.new(uri.path)
req['Content-Type'] = 'application/json'
req.body = {'foo': 'bar'}.to_json
res = https.request(req)
puts res.body
`
          },
          c: {
            title: 'C',
            code: `#include <stdio.h>
#include <curl/curl.h>
int main(void)
{
  CURL *curl;
  CURLcode res;
  curl_global_init(CURL_GLOBAL_ALL);
  curl = curl_easy_init();
  if(curl) {
    struct curl_slist *headers = NULL;
    curl_easy_setopt(curl, CURLOPT_URL, "${url}");
    headers = curl_slist_append(headers, "content-type: application/json");
    headers = curl_slist_append(headers, "accept: application/json");
    curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
    curl_easy_setopt(curl, CURLOPT_COOKIE, "foo=bar; bar=baz");
    curl_easy_setopt(curl, CURLOPT_POSTFIELDS, "{\\"foo\\": \\"bar\\"}");
    res = curl_easy_perform(curl);
    if(res != CURLE_OK)
      fprintf(stderr, "curl_easy_perform() failed: %s", curl_easy_strerror(res));
    curl_easy_cleanup(curl);
  }
  curl_global_cleanup();
  return 0;
}
`
          },
          csharp: {
            title: 'C#',
            code: `var client = new RestClient("${url}");
var request = new RestRequest(Method.POST);
request.AddHeader("x-pretty-print", "2");
request.AddHeader("content-type", "application/json");
request.AddHeader("accept", "application/json");
request.AddCookie("foo", "bar");
request.AddCookie("bar", "baz");
request.AddParameter("application/json", "{\\"foo\\": \\"bar\\"}", ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
`
          },
//           go: {
//             title: 'Go',
//             code: `package main
//
// import (
// 	"fmt"
// 	"strings"
// 	"net/http"
// 	"io/ioutil"
// )
//
// func main() {
//
// 	url := "${url}"
//
// 	payload := strings.NewReader("{\\"foo\\": \\"bar\\"}")
//
// 	req, _ := http.NewRequest("POST", url, payload)
//
// 	req.Header.Add("cookie", "foo=bar; bar=baz")
// 	req.Header.Add("accept", "application/json")
// 	req.Header.Add("content-type", "application/json")
// 	req.Header.Add("x-pretty-print", "2")
//
// 	res, _ := http.DefaultClient.Do(req)
//
// 	defer res.Body.Close()
// 	body, _ := ioutil.ReadAll(res.Body)
//
// 	fmt.Println(res)
// 	fmt.Println(string(body))
//
// }
//
// `
//           },
          jQuery: {
            title: 'jQuery',
            code: `var settings = {
  "async": true,
  "crossDomain": true,
  "url": "${url}",
  "method": "POST",
  "headers": {
    "accept": "application/json",
    "content-type": "application/json",
    "x-pretty-print": "2"
  },
  "processData": false,
  "data": "{\\"foo\\": \\"bar\\"}"
}

$.ajax(settings).done(function (response) {
  console.log(response);
});

`
          },
          XHR: {
            title: 'XMLHttpRequest',
            code: `var data = JSON.stringify({
  "foo": "bar"
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === this.DONE) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "${url}");
xhr.setRequestHeader("accept", "application/json");
xhr.setRequestHeader("content-type", "application/json");
xhr.setRequestHeader("x-pretty-print", "2");

xhr.send(data);

`
          },
//           ocaml: {
//             title: 'oCaml',
//             code: `open Cohttp_lwt_unix
// open Cohttp
// open Lwt
//
// let uri = Uri.of_string "${url}" in
// let headers = Header.add_list (Header.init ()) [
//   ("cookie", "foo=bar; bar=baz");
//   ("accept", "application/json");
//   ("content-type", "application/json");
//   ("x-pretty-print", "2");
// ] in
// let body = Cohttp_lwt_body.of_string "{\"foo\": \"bar\"}" in
//
// Client.call ~headers ~body \`POST uri
//               >>= fun (res, body_stream) ->
//       (* Do stuff with the result *)
//
// `
//           },
//           ObjectiveC: {
//             title: 'Objective-C',
//             code: `#import <Foundation/Foundation.h>
//
// NSDictionary *headers = @{ @"cookie": @"foo=bar; bar=baz",
//                            @"accept": @"application/json",
//                            @"content-type": @"application/json",
//                            @"x-pretty-print": @"2" };
// NSDictionary *parameters = @{ @"foo": @"bar" };
//
// NSData *postData = [NSJSONSerialization dataWithJSONObject:parameters options:0 error:nil];
//
// NSMutableURLRequest *request = [NSMutableURLRequest requestWithURL:[NSURL URLWithString:@"${url}"]
//                                                        cachePolicy:NSURLRequestUseProtocolCachePolicy
//                                                    timeoutInterval:10.0];
// [request setHTTPMethod:@"POST"];
// [request setAllHTTPHeaderFields:headers];
// [request setHTTPBody:postData];
//
// NSURLSession *session = [NSURLSession sharedSession];
// NSURLSessionDataTask *dataTask = [session dataTaskWithRequest:request
//                                             completionHandler:^(NSData *data, NSURLResponse *response, NSError *error) {
//                                                 if (error) {
//                                                     NSLog(@"%@", error);
//                                                 } else {
//                                                     NSHTTPURLResponse *httpResponse = (NSHTTPURLResponse *) response;
//                                                     NSLog(@"%@", httpResponse);
//                                                 }
//                                             }];
// [dataTask resume];
//
// `
//           },
//           swift: {
//             title: 'Swift',
//             code: `import Foundation
//
// let headers = [
//   "cookie": "foo=bar; bar=baz",
//   "accept": "application/json",
//   "content-type": "application/json",
//   "x-pretty-print": "2"
// ]
// let parameters = ["foo": "bar"]
//
// let postData = NSJSONSerialization.dataWithJSONObject(parameters, options: nil, error: nil)
//
// var request = NSMutableURLRequest(URL: NSURL(string: "${url}")!,
//                                         cachePolicy: .UseProtocolCachePolicy,
//                                     timeoutInterval: 10.0)
// request.HTTPMethod = "POST"
// request.allHTTPHeaderFields = headers
// request.HTTPBody = postData
//
// let session = NSURLSession.sharedSession()
// let dataTask = session.dataTaskWithRequest(request, completionHandler: { (data, response, error) -> Void in
//   if (error != nil) {
//     println(error)
//   } else {
//     let httpResponse = response as? NSHTTPURLResponse
//     println(httpResponse)
//   }
// })
//
// dataTask.resume()
//
// `
//           },
//           java_okhttp: {
//             title: 'Java / OkHttp',
//             code: `OkHttpClient client = new OkHttpClient();
//
// MediaType mediaType = MediaType.parse("application/json");
// RequestBody body = RequestBody.create(mediaType, "{\"foo\": \"bar\"}");
// Request request = new Request.Builder()
//   .url("${url}")
//   .post(body)
//   .addHeader("cookie", "foo=bar; bar=baz")
//   .addHeader("accept", "application/json")
//   .addHeader("content-type", "application/json")
//   .addHeader("x-pretty-print", "2")
//   .build();
//
// Response response = client.newCall(request).execute();
//
// `
//           },
//           java_unirest: {
//             title: 'Java / Unirest',
//             code: `HttpResponse<String> response = Unirest.post("${url}")
//   .header("cookie", "foo=bar; bar=baz")
//   .header("accept", "application/json")
//   .header("content-type", "application/json")
//   .header("x-pretty-print", "2")
//   .body("{\"foo\": \"bar\"}")
//   .asString();
//
// `
//           },
          node_http: {
            title: 'Node.js / HTTP',
            code: `var http = require('https');

var options = {
  'method': 'POST',
  'hostname': '${urlPathWithoutProtocol}',
  'port': null,
  'path': '/${urlQuery}?foo=bar&foo=baz',
  'headers': {
    'cookie': 'foo=bar; bar=baz',
    'accept': 'application/json',
    'content-type': 'application/json',
    'x-pretty-print': '2'
  }
};

var req = http.request(options, function (res) {
  var chunks = [];

  res.on('data', function (chunk) {
    chunks.push(chunk);
  });

  res.on('end', function () {
    var body = Buffer.concat(chunks);
    console.log(body.toString());
  });
});

req.write(JSON.stringify({foo: 'bar'}));
req.end();

`
          },
          node_request: {
            title: 'Node.js / Request',
            code: `var request = require("request");

var jar = request.jar();

var options = { method: 'POST',
  url: '${url}',
  qs: { foo: [ 'bar', 'baz' ] },
  headers:
    { 'x-pretty-print': '2',
      'content-type': 'application/json',
      accept: 'application/json' },
  body: { foo: 'bar' },
  json: true,
  jar: 'JAR' };

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});

`
          },
          node_unirest: {
            title: 'Node.js / unirest',
            code: `var unirest = require("unirest");
unirest.post('${url}')
  .headers({'Accept': 'application/json', 'Content-Type': 'application/json'})
  .send({"foo": "bar"})
  .end(function (response) {
    console.log(response.body);
  });
`
          }
        }
      }
    }
  }
</script>
