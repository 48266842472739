import Config from '@/config/config'
import Server from '@/service/server'
import Socket from '@/service/socket'

export default {
  namespaced: true,
  state: {
    socket: null,
    endpointId: null,
    endpointDetails: null,
    overallData: {
      endpointCount: 0,
      requestCount: 0
    },
    endpointData: {
      requestCount: 0,
      createDate: null,
      lastRequestDate: null
    },
    lastPostedDataTime: null,
    lastPostedDataTimeCount: 0,
    postedData: [],
    postedDataTotalCount: 0,
    postedDataMaxCount: 500,
    postedDataMaxCountDeleteCount: 100
  },
  getters: {
    getDetails: (state) => (traceId) => {
      let output
      output = state.postedData.filter((item) => {
        return item.traceId === traceId
      })
      return output.length > 0 ? output[0] : null
    },
    getRequestCountPerSecond: (state) => (unixtimestamp) => {
      let unix = parseInt(unixtimestamp / 1000)
      let output
      if (unix === state.lastPostedDataTime) {
        output = state.lastPostedDataTimeCount
      } else {
        output = 0
      }
      return output
    }
  },
  mutations: {
    setEndPointDetails (state, payload) {
      state.endpointDetails = payload
    },
    OnOverallData (state, overallData) {
      state.overallData = overallData
    },
    OnEndpointData (state, endpointData) {
      state.endpointData = endpointData
    },
    OnPostedData (state, postedData) {
      state.postedDataTotalCount += 1
      // First in last out in max count
      if (state.postedData.length >= state.postedDataMaxCount) {
        state.postedData.splice(0, 100)
      }
      state.postedData.push(postedData)
      // Round to nearest second
      const cachedTime = parseInt((new Date().valueOf() + 500) / 1000)
      if (state.lastPostedDataTime === cachedTime) {
        state.lastPostedDataTimeCount += 1
      } else {
        state.lastPostedDataTimeCount = 1
        state.lastPostedDataTime = cachedTime
      }
      // Note for test chart uncommnet next line and look at console
      // console.log(cachedTime)
    },
    OnEndpointCreated (state, endpointId) {
      state.endpointId = endpointId
    },
    OnInitializeSocket (state, socket) {
      state.socket = socket
    },
    OnJoinGroupSocket (state, groupId) {
      state.endpointId = groupId
    },
    DeleteItemFromPostedData (state, item) {
      state.postedData.splice(state.postedData.indexOf(item), 1)
    },
    clearPostedData (state) {
      state.postedData = []
      state.postedDataTotalCount = 0
      state.lastPostedDataTime = null
      state.lastPostedDataTimeCount = 0
    }
  },
  actions: {
    setEndPointDetails (context, payload) {
      context.commit('setEndPointDetails', payload)
    },
    clearPostedData (context) {
      context.commit('clearPostedData')
    },
    deleteItem (context, traceId) {
      // console.log('passed to action :' + traceId)
      let requestItem = context.state.postedData.filter((item) => {
        return item.traceId === traceId
      })
      context.commit('DeleteItemFromPostedData', requestItem[0])
    },
    // ----------------------------------------------------
    // HTTP/Server Actions
    // ----------------------------------------------------
    // Create new endpoint for the user
    createEndpoint () {
      return Server.CreateEndpoint()
    },
    // Get initial OverallData for the first time
    getOverallData (context) {
      Server.GetOverallData().then((result) => {
        context.state.overallData = result.body
        context.commit('OnOverallData', result.body)
      })
    },
    // Get initial EndpointData for the first time
    getEndpointData (context, id) {
      Server.GetEndpointData({id: id}).then((result) => {
        context.state.endpointData = result.body
        context.commit('OnEndpointData', result.body)
      })
    },
    // ----------------------------------------------------
    // Socket actions
    // ----------------------------------------------------
    // Initializing socket, it will create only 1 socket for multiple calls (Singleton)
    initializeSocket (context) {
      if (!context.state.socket) {
        var socket = new Socket(Config.Urls.SocketUrl)
        socket.onPublicData = (payload) => context.dispatch('onPublicDataSocket', payload)
        socket.onGroupData = (groupId, payload) => context.dispatch('onGroupDataSocket', payload)
        return new Promise((resolve, reject) => {
          socket.start().then(() => {
            context.commit('OnInitializeSocket', socket)
            resolve()
          }, error => {
            reject(error)
          })
        })
      }
    },
    // Join a user to a group for the connected socket
    joinGroupSocket (context, groupId) {
      return new Promise((resolve, reject) => {
        context.state.socket.join(groupId).then(() => {
          context.commit('OnJoinGroupSocket', groupId)
          resolve()
        }, error => {
          reject(error)
        })
      })
    },
    // This method will be called by the connected socket when a public data is posted
    onPublicDataSocket (context, payload) {
      if (payload && payload.type) {
        context.commit('On' + payload.type, payload.message)
        return
      }
      console.warn('There is and unhandled message in socket:')
      console.warn(payload)
    },
    // This method will be called by the connected socket when a group-based data is posted
    onGroupDataSocket (context, payload) {
      if (payload && payload.type) {
        context.commit('On' + payload.type, payload.message)
        return
      }
      console.warn('There is and unhandled message in socket:')
      console.warn(payload)
    }
  }
}
