import Vue from 'vue'
import Config from './config/config'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'vue-awesome/icons/flag'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import TreeView from 'vue-json-tree-view'
import VueScrollTo from 'vue-scrollto'
import VueHighcharts from 'vue-highcharts'
import Highcharts from 'highcharts/highstock'
import VueLodash from 'vue-lodash'
import VueHighlightJS from 'vue-highlightjs'
import VueNumerals from 'vue-numerals'
// eslint-disable-next-line
import filters from '@/filters'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueAnalytics from 'vue-analytics'
import VueMq from 'vue-mq'

Vue.use(VueNumerals)
Vue.use(VueHighlightJS)
Vue.use(VueScrollTo, Config.scrollTo)
Vue.use(TreeView)
Vue.use(BootstrapVue)
Vue.component('icon', Icon)
Vue.use(VueHighcharts, {Highcharts})
Vue.use(VueLodash)

Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 1250,
    lg: Infinity,
  }
})

Vue.use(VueAnalytics, {
  id: 'UA-121463437-1',
  router
})
/* eslint-disable no-new */

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
  components: {
    App,
    Icon
  },
  template: '<App/>'
})
