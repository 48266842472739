import Vue from 'vue'
import VueResource from 'vue-resource'
import Config from '../config/config'

Vue.use(VueResource)

const Api = {
  CreateEndpoint: {method: 'POST', url: Config.Urls.CreateEndpoint},
  GetOverallData: {method: 'GET', url: Config.Urls.GetOverallDataUrl},
  GetEndpointData: {method: 'GET', url: Config.Urls.GetEndpointDataUrl}
}

export const Server = Vue.resource('someid{/id}', {}, Api)

export default Server
