export default {
  language: 'en',
  app_title: 'uBeac Hook',
  create_hook: 'Create Hook',
  linkedin: 'https://www.linkedin.com/company/ubeac/',
  twitter: 'https://twitter.com/ubeacio',
  facebook: 'https://www.facebook.com/ubeac.io',
  youtube: 'https://www.youtube.com/channel/UCxHu4X1zLUkS8amK5EwdBXg/featured',
  github: 'https://ubeac.github.io/docs/',
  contact: 'info@ubeac.io',
  landing: {
    slogan: 'Secure Data, Adaptive Intelligence',
    cta_button: 'Create Endpoint',
    cta_button_loading: 'Creating new Endpoint ...',
    banner_title: `Inspect Your HTTP/MQTT Requests`,
    banner_description: `
Log, inspect and debug HTTP/MQTT requests and webhooks in a human-friendly way. Quickly test the connectivity of your devices or application in real-time, so you can get back to working on your use-case.
Configure Your Device and Monitor Data Instantly`,
    second_cta_text: `Rest Easy Knowing Your Devices are Connected!`
  },
  subscribe: 'Subscribe now and get our newsletter',
  subscribe_details: 'Sign up to receive our newsletters with the latest updates from the uBeac team.',
  footer: {
    about_us: 'About us',
    about_us_desc: 'uBeac offers an end-to-end IoT cloud platform that transforms the way businesses create value from connected devices and user interactions.',
    contact_us: 'Contact us',
    address: '180 West Beaver Creek Road, Richmond Hill, ON, L4B1B4, CANADA',
    phone: 'Phone: (647) 696 4343',
    email: 'Email:'
  },
  copyright: 'COPYRIGHT © 2019 UBEAC, ALL RIGHTS RESERVED. PRIVACY POLICY / TERMS OF SERVICE.'
  // copyright: 'Copyright© 2019 uBeac All rights reserved.'
}
